var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "subHeader base noFlex qnaManagement" }, [
        _vm._m(0),
        _c("div", { staticClass: "module-config" }, [
          _c("div", { staticClass: "wtBox config-list" }, [
            _c("header", { staticClass: "flexL" }, [
              _c("h3", [
                _vm._v(" " + _vm._s(_vm.category)),
                _c("span", [_vm._v(_vm._s(_vm.title))])
              ])
            ]),
            _c("p", [
              _c("span", [_vm._v(_vm._s(_vm.username))]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.moment(_vm.createdAt).format("YYYY.MM.DD HH:mm"))
                )
              ])
            ]),
            _c("article", [_c("pre", [_vm._v(_vm._s(_vm.content))])])
          ]),
          _c("div", { staticClass: "wtBox config-list" }, [
            _vm._m(1),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.replys,
                  expression: "replys"
                }
              ],
              attrs: { cols: "30", rows: "10" },
              domProps: { value: _vm.replys },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.replys = $event.target.value
                }
              }
            }),
            _c(
              "div",
              { staticClass: "buttonWrap" },
              [
                _c("router-link", { attrs: { to: "/admin/qna" } }, [
                  _c("button", { staticClass: "right line" }, [_vm._v("목록")])
                ]),
                _c(
                  "button",
                  { staticClass: "pointOut", on: { click: _vm.submit } },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.status ? "답변수정" : "답변작성") + " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c("transition", { attrs: { name: "fade" } }, [_c("router-view")], 1)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "module-header" }, [
      _c("h2", [_vm._v("문의사항 관리")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "flexL" }, [
      _c("h3", [_vm._v("답변하기")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }